// Sprinkle in some vanilla js

// *** ZOOM IMG
// Display work images in bigger size
const zoomImgs = document.querySelectorAll('.js-zoom-img');

// Add click for every zoom img
for (let i = 0; i < zoomImgs.length; ++i) {
    // Click to activate zoom
    zoomImgs[i].addEventListener("click", (e) => {
        // Prevent default behaviour
        e.preventDefault();

        // Get current zoom img
        const zoomItem = e.currentTarget;

        // Get parent
        const zoomBox = zoomItem.parentElement;

        // Toggle active class
        zoomBox.classList.toggle("is-zoomed");

        // Find sources and extend to load bigger images
        const imgSources = zoomItem.querySelectorAll('source');
        for (let i = 0; i < imgSources.length; ++i) {
            imgSources[i].setAttribute('sizes', '100vw');
        }

        // Show modal
        document.body.classList.toggle("has-zoom-img");
    });
}


// *** TIME SPENT

// Get the reference to the <span> element
const timeSpentElement = document.querySelector('.js-time-spent');

let seconds = 0;

// Update the time every second
setInterval(() => {
    seconds++;
    timeSpentElement.textContent = `${seconds} s`;
}, 1000);



// *** CONTINUE
// Read more paragraphs
// Look for buttons
const continueBtns = document.querySelectorAll('.js-continue-box');

// Add click listener for every client
for (let i = 0; i < continueBtns.length; ++i) {
    continueBtns[i].addEventListener("click", (event) => {
        // Prevent default behaviour
        event.preventDefault();

        // Get parent
        const continueBox = event.currentTarget.parentElement;

        // Get next hidden p
        const hiddenP = continueBox.querySelector('[aria-hidden="true"]');

        if (hiddenP) {
            // Show hidden p
            hiddenP.setAttribute('aria-hidden', 'false');

            // Focus previously hidden p
            hiddenP.focus();

            // Find previous p
            let previousP = hiddenP.previousElementSibling;

            // Mark previous p as read after a moment
            setTimeout(function () {
                previousP.classList.add('is-read');
            }, 300); // wait a tiny bit to feel in sync with second handle

            // Is there a next p?
            const anotherHiddenP = continueBox.querySelector('[aria-hidden="true"]');

            // Change button text
            if (!anotherHiddenP) {
                event.currentTarget.querySelector('.js-continue-btn').querySelector('span').innerHTML = 'Repeat';
                event.currentTarget.classList.add('continue__btn--restart');
            }

        } else {
            // Reset all p
            const allP = continueBox.querySelectorAll('p');

            for (let i = 0; i < allP.length; ++i) {
                if (i === 0) {
                    allP[i].setAttribute('aria-hidden', 'false');
                    allP[i].classList.remove('is-read');
                } else {
                    allP[i].setAttribute('aria-hidden', 'true');
                    allP[i].classList.remove('is-read');
                }
            }

            // Change button text
            event.currentTarget.querySelector('.js-continue-btn').querySelector('span').innerHTML = 'Continue';
            event.currentTarget.classList.remove('continue__btn--restart');

        }
    });
}